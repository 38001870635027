<template>
  <div class="searchResult_container" ref="pageScroll">
    <div class="searchResult_title_block">
      <div class="searchResult_title" style="flex: 1 0 0">
        <img :src="require(`@/assets/images/challenge/phone.png`)" alt="" />号码
      </div>
      <div class="searchResult_title" style="flex: 24vw 0 0">
        <img
          :src="require(`@/assets/images/challenge/hospital.png`)"
          alt=""
        />医院
      </div>
      <div class="searchResult_title" style="flex: 22vw 0 0">
        <img
          :src="require(`@/assets/images/challenge/record.png`)"
          alt=""
        />录制状态
      </div>
      <div class="searchResult_title" style="flex: 26vw 0 0">
        <img
          :src="require(`@/assets/images/challenge/time.png`)"
          alt=""
        />录制时间
      </div>
    </div>
    <div class="searchResult_title_block" style="flex: 1 0 0">
      <itemScroll ref="itemScroll">
        <div>
          <div
            v-for="citems in data"
            :key="citems.id"
            class="searchResult_list_block"
          >
            <div class="searchResult_list" style="flex: 1 0 0">
              {{ citems.phone.substr(-4) }}
            </div>
            <div class="searchResult_list" style="flex: 24vw 0 0">
              {{ citems.hospital }}
            </div>
            <div class="searchResult_list" style="flex: 22vw 0 0">
              {{ getStatus(citems) }}
            </div>
            <div class="searchResult_list" style="flex: 26vw 0 0">
              {{ citems.create_time }}
            </div>
          </div>
        </div>
      </itemScroll>
    </div>
    <div class="illustrate">
      <span> 录制状态详解 </span>
      <span>未创建：尚未登录，无录制计划</span>
      <span>已创建：已创建录制计划，未开始录制</span>
      <span>进行中：正在进行录制</span>
      <span>审核中：录制审核中，常规1-3个工作日</span>
      <span>已审核：审核通过，需签署协议</span>
      <span>审核未通过：审核已退回，可通过短信查看具体退回原因</span>
      <span>已完成：已签署协议</span>
    </div>
  </div>
</template>
<script>
import itemScroll from "@/components/unit/itemScroll";
export default {
  name: "searchResult",
  data() {
    return {
      scroll: null,
      loading: false,
      phone: "",
      data: [],
      status: [
        "未创建",
        "已创建",
        "进行中",
        "审核中",
        "已审核",
        "审核未通过",
        "已完成",
      ],
    };
  },
  created() {
    this.data = this.$tools.getStorage("searchResult", sessionStorage);
  },
  components: {
    itemScroll,
  },
  mounted() {
    document.getElementsByTagName("title")[0].innerHTML = "专家查询";
    this.$nextTick(() => {
      this.$refs.itemScroll.initScroll();
    });
  },
  methods: {
    getStatus(item) {
      if (item.audit_status === "") return this.status[0];
      if (item.audit_status === 0 && item.status === 0) return this.status[1];
      if (item.audit_status === 0 && item.status === 1) return this.status[2];
      if (item.audit_status === 15) return this.status[3];
      if (item.audit_status === 20 && item.sign_time) return this.status[6];
      if (item.audit_status === 20) return this.status[4];
      if (item.audit_status === 30) return this.status[5];
    },
  },
  beforeUnmount() {
    this.$tools.removeStorage("searchResult", sessionStorage);
  },
};
</script>
<style>
.searchResult_container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: url("../../../assets/images/challenge/backgroud.png");
  background-size: 100% 100%;
  padding: 8vw 4vw;
  display: flex;
  flex-direction: column;
  padding-bottom: 4vw;
}
.searchResult_title_block {
  display: flex;
  color: #9e004c;
  justify-content: space-between;
  margin-top: 2vw;
}
.searchResult_title {
  display: flex;
  justify-content: center;
  color: #9e004c;
  background: #fff;
  padding: 1vw;
  border-radius: 1vw;
  position: relative;
  margin-right: 2vw;
}
.searchResult_title:last-child {
  margin-right: 0;
}
.searchResult_title img {
  width: 6vw;
  height: auto;
  position: absolute;
  top: -5vw;
  left: 50%;
  transform: translateX(-50%);
}
.searchResult_list {
  display: flex;
  color: #fff;
  background: #d7a0af;
  padding: 1vw;
  border-radius: 1vw;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 2vw;
}
.searchResult_list:last-child {
  margin-right: 0;
}
.searchResult_content {
  padding: 5.6vw 9.6vw;
}
.searchResult_list_block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2vw;
}
.illustrate {
  display: flex;
  flex-direction: column;
  color: #fff;
  background: #d7a0af;
  border-radius: 1vw;
  padding: 1vw;
  font-size: 3vw;
}
.illustrate span {
  margin: 1vw;
}
.illustrate span:first-child {
  font-weight: bold;
}
</style>
